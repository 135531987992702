<template>
  <AdminPage>
      <nav class="level">
      <!-- Left side -->
      <div class="level-left">
        <div class="level-item">
          <div 
            class="field has-addons"
          >
            <p class="control">
              <span class="select">
                <select>
                  <option>All users</option>
                  <!-- <option>Top 10% transactions</option>
                  <option>Active - has payout balance</option>
                  <option>Active - no payout balance</option>
                  <option>Cancelled</option> -->
                </select>
              </span>
            </p>
            <p class="control">
              <span class="select">
                <select>
                  <option>All locations</option>
                  <!-- <option>Burlington, VT</option>
                  <option>..</option> -->
                </select>
              </span>
            </p>
          </div>
        </div>
      </div>
      <!-- Right side -->
      <div class="level-right">
        <p class="level-item"><strong>{{ usersCount }}</strong></p>
      </div>
    </nav>

    <b-table
      :data="users"
      :loading="loading"
      :bordered="true"
      :striped="true"
      :narrowed="true"
      :hoverable="true"
      default-sort="inserted_at"
      default-sort-direction="desc"
    >
      <b-table-column
        field="inserted_at"
        label="Joined"
        sortable
        v-slot="props"
      >
        {{ new Date(props.row.inserted_at).toLocaleDateString() }} {{ new Date(props.row.inserted_at).toLocaleTimeString() }}
      </b-table-column>

      <b-table-column
        field="updated_at"
        label="Last Updated"
        sortable
        v-slot="props"
      >
        {{ new Date(props.row.updated_at).toLocaleDateString() }} {{ new Date(props.row.updated_at).toLocaleTimeString() }}
      </b-table-column>

      <b-table-column
        field="name"
        label="Name"
        sortable
        v-slot="props"
      >
        {{ props.row.name }}
      </b-table-column>

      <b-table-column
        field="email"
        label="Email"
        sortable
        v-slot="props"
      >
        {{ props.row.email }}
      </b-table-column>

      <b-table-column
        field="approved_claims"
        label="Approved Claims"
        numeric
        sortable
        v-slot="props"
      >
        {{ props.row.approved_claims }}
      </b-table-column>

      <b-table-column
        field="total_award"
        label="Lifetime Awards"
        sortable
        v-slot="props"
      >
        {{ USD(props.row.total_award).format() }}
      </b-table-column>

      <b-table-column
        field="balance"
        label="Current Balance"
        sortable
        v-slot="props"
      >
        {{ USD(props.row.balance).format() }}
      </b-table-column>

      <template #empty>
        <div class="has-text-centered">No users</div>
      </template>
    </b-table>
  </AdminPage>
</template>

<script>
import AdminPage from "@/components/AdminPage.vue";
import api from "@/service/api.js";

export default {
  components: {
    AdminPage
  },
  computed: {
    currentUser: function() {
      return this.$store.getters.currentUser;     
    },
    usersCount: function() {
      return this.users.length
    }
  },
  data() {
    return {
      loading: false,
      users: []
    }
  },
  mounted() {
    this.getUsers()
  },
  methods: {
    getUsers() {
      this.loading = true

      api.getAdminUsers(this.currentUser.slug)
        .then(resp => {
          this.users = resp.data
        })
        .catch(error => {
          this.error = error.data
        })
        .finally(() => {
          this.loading = false
        })
    },
  }
}
</script>
